import { Card, CardContent, Typography, Container, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useMediaQuery } from "@mui/material";

const ConfigBox = () => {
  const isMobile = useMediaQuery("(max-width: 500px)");
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5">Dockerfile for Node.js Application</Typography>
        <Stack
          flexItem
          justifyContent="space-between"
          direction={isMobile ? "column" : "row"}
          spacing={4}
        >
          <Typography variant="caption" align="left">
            22 mins ago &#x2022; Utkarsh Tripathi
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="space-around">
            <Chip size={isMobile ? "small" : ""} label="docker" />
            <Chip size={isMobile ? "small" : ""} label="node.js" />
            <Chip size={isMobile ? "small" : ""} label="easy" />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ConfigBox;
