import React from "react";
import { Box, Container } from "@mui/system";
const Layout = (props) => {
  return (
    <Box
      style={{
        fontFamily: "JetBrains Mono, Roboto, Verdana, sans-serif",
      }}
      mt={8}
    >
      <Container>{props.children}</Container>
    </Box>
  );
};

export default Layout;
