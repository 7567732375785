import React from "react";
import Lottie from "lottie-react";
import styles from "./styles.module.scss";
import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const Landing = () => {
  const isMobile = useMediaQuery("(max-width: 470px)");
  return (
    <div className={styles.container}>
      <Typography
        sx={{
          fontSize: isMobile ? "2rem" : "3rem",
          fontFamily: "JetBrains Mono",
          marginTop: "10rem",
          background: "linear-gradient(to right, #5614b0, #dbd65c)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
        className={styles.title}
        align="center"
      >
        One place for all your configurations
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <div className={styles.info}>
          <Paper
            elevation={4}
            sx={{ width: "250px", padding: "1rem", margin: "1rem" }}
          >
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h5" sx={{ paddingBottom: "1rem" }}>
                  Share your configs
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Share all your configs of Docker, docker-compose, kubernetes,
                  github actions and many more to the community...
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={4}
            sx={{ width: "250px", padding: "1rem", margin: "1rem" }}
          >
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ paddingBottom: "1rem" }}
                  align="center"
                >
                  Use the generators
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Share all your configs of Docker, docker-compose, kubernetes,
                  github actions and many more to the community...
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={4}
            sx={{ width: "250px", padding: "1rem", margin: "1rem" }}
          >
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h5" sx={{ paddingBottom: "1rem" }}>
                  Get Help
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Share all your configs of Docker, docker-compose, kubernetes,
                  github actions and many more to the community...
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Box>
    </div>
  );
};

export default Landing;
