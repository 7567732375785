import { Box, Stack, Typography } from "@mui/material";
import ConfigBox from "../../components/ConfigBox";

const Config = () => {
  return (
    <Box>
      <Typography align="center" variant="h1">
        Configs
      </Typography>
      <Stack direction="column" spacing={4} mt={4}>
        <ConfigBox />
      </Stack>
    </Box>
  );
};

export default Config;
