import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Layout from "./Layout";
import Navbar from "./components/Navbar";
import Landing from "./pages/Landing/Landing";
import { Routes, Route } from "react-router-dom";
import Config from "./pages/Configs/Config";
//changes 
function App() {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Layout>
        <Routes>
          <Route path="/configs" element={<Config />} />
          <Route path="/" element={<Landing />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
